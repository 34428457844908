import React, { useState, useEffect } from 'react';
import logoInfogreffe from "../../assets/logoOliver_vanillaDark3.jpg";
import logoMesFormalites from "../../assets/MesFormalites.Legal4.png";
import Cgus from "../../components/auth/Cgus";
import { fetchData } from "../../utils/ask_api";
import { useAppContext } from "../../context/AppContext";
import ContactInformationForm from "../../components/chat_view/chat_message/render_step_content_reservation/ContactInformations"
import ValidationForm from "../../components/chat_view/chat_message/render_step_content_reservation/ValidattionForm"
import ConfirmationOrder from "../../components/chat_view/chat_message/render_step_content_reservation/ConfirmationOrder"
import PaymentForm from "../../components/chat_view/chat_message/render_step_content_reservation/PaymentForm"
import EditableeDevisTable from '../registreFormalites/EditableeDevisTable';
import Modal from '../Modal';

const ValidationDevis = ({
    showPaymentForm,
    setShowPaymentForm,
    modalReservationOpen,
    setModalReservationOpen,
    eDevisIsSand,
    selectedFormality,
    setModalProfileOpen,
    myRef,
    isMobile
}) => {

    const { clearChat, currentUser, location, subDomains, subdomain } = useAppContext();
    const nomPrenon = currentUser?.displayName.split(" ") || ""
    const [companyName, setCompanyName] = useState("");
    const [sirenNumber, setSirenNumber] = useState("");
    const [tel, setTel] = useState("");
    const [firstname, setFirstName] = useState(nomPrenon[0] || "");
    const [lastName, setLastName] = useState(nomPrenon[1] || "");
    const [email, setEmail] = useState(currentUser?.email || "");

    const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
    const [isSirenNumberValid, setIsSirenNumberValid] = useState(true);
    const [isTelValid, setIsTelValid] = useState(true);
    const [isFirstNameValid, setIsFirstNameValid] = useState(true);
    const [isLastNameValid, setIsLastNameValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);

    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptCgus, setAcceptCgus] = useState(false);

    const [modalCguOpen, setModalCguOpen] = useState("");
    const [modalTile, setModalTitle] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);

    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const [packGlobal, setPackGlobal] = useState(false);
    const [prixHT, setPrixHT] = useState(0);
    const [piecesRequises, setPiecesRequises] = useState([]);
    const [fraisAll, setfraisAll] = useState(null);

    console.log("selectedFormality ================>: ", selectedFormality);
    useEffect(() => {
        if (currentUser?.displayName) {
            const parts = currentUser.displayName.split(" ");
            if (parts.length > 0) {
                setFirstName(parts[0]);
                setLastName(parts[parts.length - 1]);
            }
        } else {
            setFirstName('');
            setLastName('');
        }

        setEmail(currentUser?.email || '');
        const phoneNumber = currentUser?.providerData?.[0]?.phoneNumber;
        if (phoneNumber) {
            setTel(phoneNumber);
        } else {
            setTel('');
        }
    }, [currentUser]); // Dépendance à currentUser pour réagir à ses changements

    useEffect(() => {
        if (myRef && myRef.current) {
            myRef.current.scrollIntoView({ behavior: 'smooth' }); // Défilement fluide
        }
    }, [myRef]);

    const Capitalize = (str) => {

        return str?.charAt(0).toUpperCase() + str?.slice(1) || '';
    }

    const [billingInformation, setBillingInformation] = useState({
        prenom: firstname,
        nom: lastName,
        email: email,
        tel: selectedFormality?.telephone,
        companyName: selectedFormality.nomEntreprise,
        address: '',
        totalAmount: selectedFormality.eDevis.amount_devis,
        serviceDescription: `Formalité de ${Capitalize(selectedFormality.categorie)} : ${Capitalize(selectedFormality.titreFormalitie)}`,
    });

    const [requiredDocuments, setRequiredDocuments] = useState(piecesRequises);
    console.log('billingInformation:', billingInformation);
    console.log('requiredDocuments:', requiredDocuments);

    useEffect(() => {
        setRequiredDocuments(piecesRequises);
    }, [piecesRequises]);


    const [validations, setValidations] = useState({
        isFirstNameValid: isFirstNameValid,
        isLastNameValid: isLastNameValid,
        isCompanyNameValid: isCompanyNameValid,
        isEmailValid: isEmailValid,
        isTelValid: isTelValid,
        isAddressValid: false,
    });
    const validateEmail = (email) => {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email.toLowerCase());
    };


    const updateBillingInfo = (newBillingInfo) => {
        setBillingInformation(prev => ({ ...prev, ...newBillingInfo }));

        if (newBillingInfo.hasOwnProperty('prenom')) {
            setValidations(prev => ({ ...prev, isFirstNameValid: newBillingInfo.prenom.trim().length > 0 }));
        }
        else if (newBillingInfo.hasOwnProperty('nom')) {
            setValidations(prev => ({ ...prev, isLastNameValid: newBillingInfo.nom.trim().length > 0 }));
        }
        else if (newBillingInfo.hasOwnProperty('email')) {
            setValidations(prev => ({ ...prev, isEmailValid: validateEmail(newBillingInfo.email) }));
        }
        else if (newBillingInfo.hasOwnProperty('tel')) {
            setValidations(prev => ({ ...prev, isTelValid: /^[0-9+\s()-]+$/.test(newBillingInfo.tel.trim()) }));
        }
        else if (newBillingInfo.hasOwnProperty('address')) {
            setValidations(prev => ({ ...prev, isAddressValid: newBillingInfo.address.trim().length > 0 }));
        } else if (newBillingInfo.hasOwnProperty('companyName')) {
            setValidations(prev => ({ ...prev, isCompanyNameValid: newBillingInfo.companyName.trim().length >= 0 }));
        }
    };

    const nextStep = () => {
        setCurrentStep((prevStep) => (prevStep < 4 ? prevStep + 1 : prevStep));
    };

    const prevStep = () => {
        setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
    };

    // Update event handlers
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateBillingInfo({ [name]: value });
    };

    const handleSubmitFormalitie = async (billingInformation, piecesRequises) => {
        console.log("handleSubmitFormalitie");
        try {
            // const nomPrenon = currentUser.displayName.split(" ")
            const formalitieData = selectedFormality

            formalitieData.billing_information = billingInformation
            formalitieData.dateReservation = new Date().toISOString();
            console.log("fetch formalitieData:", formalitieData)
            if (formalitieData.required_documents) {
                formalitieData.required_documents = formalitieData.required_documents.map(doc => {
                    if (doc.uploadDate) {
                        doc.uploadDate = new Date(doc.uploadDate.seconds * 1000).toISOString();
                    }
                    return doc;
                });
            }
            if (formalitieData.timestamp) {
                formalitieData.timestamp = new Date(formalitieData.timestamp.seconds * 1000).toISOString();
            }
            if (formalitieData?.assignedAt) {
                formalitieData.assignedAt = new Date(formalitieData.assignedAt.seconds * 1000).toISOString();
            }

            const d = await fetchData("update_formalites", formalitieData, "POST");
            console.log(d);
            window.scrollTo(0, 0);
            // setModalProfileOpen(false);
            // setModalOpen(true);
            alert("Votre paiement est confirmé et la facture a été envoyée par e-mail. Rendez-vous dans 'Mon Compte - Formalités' pour télécharger vos documents.\nMerci pour votre confiance ! ")
            setModalReservationOpen(false)
            clearChat()
            // resetForm();
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
        }
    };

    const resetForm = () => {
        setCompanyName("");
        setSirenNumber("");
        setTel("")

        setIsCompanyNameValid(true);
        setIsSirenNumberValid(true);
        setIsTelValid(true)

        setAcceptCgus(false);
        setAcceptTerms(false);



        setModalReservationOpen(false);
    };

    const handleNextStepClick = () => {
        setAttemptedSubmit(true); // Indique qu'une tentative de soumission a eu lieu
        // Vérifie si toutes les conditions de validité sont remplies
        console.log("handleNextStepClick: ", isTelValid)
        if (
            companyName &&
            (selectedFormality.categorie === "creation" || sirenNumber) &&
            firstname && lastName &&
            email && tel &&
            isCompanyNameValid &&
            (selectedFormality.categorie === "creation" || isSirenNumberValid) &&
            isFirstNameValid && isLastNameValid &&
            isEmailValid && isTelValid
        ) {
            if (selectedFormality.categorie === "modification" || selectedFormality.categorie === "cessation") {
                handleSubmitFormalitie(null, piecesRequises)
            } else {
                nextStep(); // Tout est valide, passe à l'étape suivante
            }
        } else {
            // Sinon, vérifie chaque condition et focalise sur le premier champ invalide
            console.log("isTelValid: ", isTelValid)
            console.log(`Tel: '${tel}'`,)
            console.log("isCompanyNameValid: ", isCompanyNameValid)
            console.log("companyName: ", companyName)
            if (!companyName) {
                document.getElementById('companyName').focus();
            } else if (selectedFormality.categorie !== "creation" && (!sirenNumber)) {
                document.getElementById('sirenNumber').focus();
            } else if (!firstname) {
                document.getElementById('prenom').focus();
            } else if (!lastName) {
                document.getElementById('nom').focus();
            } else if (!email) {
                document.getElementById('email').focus();
            } else if (!tel) {
                document.getElementById('tel').focus();
            }
        }
    };

    return (
        <>
            <div className={`w-full mt-1 ${eDevisIsSand && 'bg-[#EDF5FE]'} rounded-2xl`}>
                <div className="">
                    <div className="signupFormContainer w-full">
                        <div className=''>
                            {eDevisIsSand && (
                                <div className={`flex flex-col p-1 px-4 my-2 w-[${isMobile ? '300px' : '700px'}] bg-white rounded-lg`}>
                                    {showPaymentForm ? (
                                        <PaymentForm
                                            currentStep={2}
                                            setCurrentStep={setCurrentStep}
                                            categoryFormalitie={selectedFormality.categorie}
                                            titleFormalitie={selectedFormality.titreFormalitie}
                                            acceptTerms={acceptTerms}
                                            acceptCgus={acceptCgus}
                                            Capitalize={Capitalize}
                                            setAcceptTerms={setAcceptTerms}
                                            setAcceptCgus={setAcceptCgus}
                                            setModalTitle={setModalTitle}
                                            setModalCguOpen={setModalCguOpen}
                                            handleSubmitFormalitie={handleSubmitFormalitie}
                                            handleInputChange={handleInputChange}
                                            billingInformation={billingInformation}
                                            validations={validations}
                                            piecesRequises={piecesRequises}
                                            prevStep={prevStep}
                                            eDevisIsSand={eDevisIsSand}
                                            setShowPaymentForm={setShowPaymentForm}
                                        />
                                    ) : (
                                        <>
                                            <EditableeDevisTable
                                                formality={selectedFormality}
                                                rowsAccompanyingCostsA={selectedFormality?.eDevis.rowsAccompanyingCosts}
                                                rowsLegalCostsA={selectedFormality?.eDevis.rowsLegalCosts}
                                                eDevisIsSand={eDevisIsSand}
                                            />
                                            <div
                                                className='loginButtonWrapper my-2'>
                                                <button onClick={() => setShowPaymentForm(true)} ref={myRef} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none">
                                                    Valider et payer
                                                </button>
                                            </div>
                                        </>

                                    )}
                                </div>

                            )}

                        </div>
                    </div>
                </div>

            </div >

            < Modal title={modalTile} modalOpen={modalCguOpen} setModalOpen={setModalCguOpen} modalSize={"cgus"} >
                <Cgus content={modalTile}></Cgus>
            </Modal >

            <Modal
                title=""
                modalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                modalSize={"auto"}
            >
                <div className="flex flex-col items-center justify-center p-4">
                    <img src={`${subDomains.includes(subdomain) ? logoMesFormalites : logoInfogreffe}`} alt="Logo" className="w-40 mb-6" />

                    <p className="text-center mb-6"> Votre paiement est confirmé et la facture a été envoyée par e-mail. Rendez-vous dans 'Mon Compte - Formalités' pour télécharger vos documents.</p>
                    <p className="text-center mb-6">  Merci pour votre confiance !</p>
                    <button
                        onClick={() => {
                            setModalOpen(false)
                            setModalReservationOpen(false)
                            clearChat()
                        }}
                        className="signupFormContainerContinuer text-white font-bold"
                    >
                        OK
                    </button>
                </div>
            </Modal>


        </>
    );
};

export default ValidationDevis;
