import { useState, useEffect } from "react";
import { onSnapshot, collection, query, where, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase.config";
import { useAppContext } from "../../context/AppContext";
import RegistreFormalitiesTable from "./RegistreFormalitiesTable";

const FormalitiesAll = ({ setOpen }) => {
    const [formalities, setFormalities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [categoryDetails, setCategoryDetails] = useState(null);
    const { currentUser, isAdmin, isformaliste, currentUserData } = useAppContext();

    // Récupérer les détails de la catégorie du formaliste
    useEffect(() => {
        const fetchCategoryDetails = async () => {
            if (isformaliste && currentUserData?.categoriesFormalites?.[0]) {
                try {
                    const categoryRef = doc(db, 'categoriesFormalites', currentUserData.categoriesFormalites[0]);
                    const categorySnap = await getDoc(categoryRef);
                    if (categorySnap.exists()) {
                        setCategoryDetails(categorySnap.data());
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération des détails de la catégorie:", error);
                }
            }
        };

        fetchCategoryDetails();
    }, [currentUserData, isformaliste]);

    useEffect(() => {
        let unsubscribe;

        const fetchFormalities = () => {
            try {
                const formalitiesRef = collection(db, "formalities");

                unsubscribe = onSnapshot(
                    formalitiesRef,
                    (querySnapshot) => {
                        const formalitiesList = [];
                        querySnapshot.forEach((doc) => {
                            const data = doc.data();

                            const formalitiesWithId = Object.entries(data)
                                .map(([key, value]) => ({
                                    ...value,
                                    id: key,
                                }))
                                .filter(formalite => {
                                    if (isAdmin) {
                                        return true;
                                    } else if (isformaliste && currentUserData?.categoriesFormalites?.[0]) {
                                        return currentUserData.categoriesFormalites[0] === formalite.categorieId;
                                    }
                                    return false;
                                });

                            formalitiesList.push(...formalitiesWithId);
                        });

                        setFormalities(formalitiesList);
                        setLoading(false);
                    }
                );

            } catch (error) {
                console.error("Erreur lors de la récupération des formalités:", error);
                setLoading(false);
            }
        };

        if (isAdmin || (isformaliste && currentUserData?.categoriesFormalites)) {
            fetchFormalities();
        } else {
            setLoading(false);
        }

        return () => unsubscribe && unsubscribe();
    }, [isAdmin, isformaliste, currentUserData]);

    if (loading) {
        return (
            <div className="w-full flex justify-center items-center py-10">
                <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    // Adapter le titre selon le rôle
    const getTitle = () => {
        if (isAdmin) {
            return "Registre de formalités - Admin";
        } else if (isformaliste && categoryDetails?.nom) {
            return `Registre de formalités - ${categoryDetails.nom}`;
        }
        return "Registre de formalités";
    };

    return (
        <div className="w-full">
            {/* <div className="mb-6">
                <h1 className="text-xl font-bold">{getTitle()}</h1>
                {isformaliste && categoryDetails && (
                    <div className="mt-2 text-sm text-gray-600">
                        <p>SIRET : {categoryDetails.siret}</p>
                        <p>{categoryDetails.description}</p>
                    </div>
                )}
            </div> */}

            {/* {formalities.length > 0 ? ( */}
            <RegistreFormalitiesTable
                formalities={formalities}
                setFormalities={setFormalities}
                isAdmin={isAdmin}
                titreRegistre={getTitle()}
            />
        </div>
    );
};

export default FormalitiesAll;