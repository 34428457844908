import React, { createContext, useContext, useEffect, useState } from 'react';
import { db } from "../firebase.config";
import { addDoc, arrayUnion, collection, doc, limit, onSnapshot, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";
import { updateMessage, update_last_system_message_tokens, update_last_system_message_documents, createMessage } from '../utils/conversation';
import { getAuth, applyActionCode } from "firebase/auth";
import { fetchData } from '../utils/ask_api';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { getEnvConfig } from '../utils/config';

const liensUtilesDebug = [
    "<a href=\"https://www.infogreffe.fr/formalites/declarer-des-beneficiaires-effectifs--rbe-\">Infogreffe - Déclarer les bénéficiaires effectifs (RBE)</a> ",
    "<a href=\"https://datainfogreffe.fr/register\">DataInfogreffe - Création de compte</a> ",
    "<a href=\"https://formalites.entreprises.gouv.fr/\">Guichet unique</a> ",
    "<a href=\"https://www.infogreffe.fr/formalites\">Page Formalités du site Infogreffe.fr</a> ",
    "<a href=\"https://datainfogreffe.fr/\">DataInfogreffe</a> ",
    "<a href=\"https://www.infogreffe.fr/\">Infogreffe - Entreprendre en confiance</a> ",
    "<a href=\"https://www.infogreffe.fr/formalites/declarer-des-beneficiaires-effectifs--rbe-\">Infogreffe - Déclarer les bénéficiaires effectifs (RBE)</a> ",
    "<a href=\"https://datainfogreffe.fr/register\">DataInfogreffe - Création de compte</a> ",
    "<a href=\"https://formalites.entreprises.gouv.fr/\">Guichet unique</a> ",
    "<a href=\"https://www.infogreffe.fr/formalites\">Page Formalités du site Infogreffe.fr</a> ",
    "<a href=\"https://datainfogreffe.fr/\">DataInfogreffe</a> ",
    "<a href=\"https://www.infogreffe.fr/\">Infogreffe - Entreprendre en confiance</a> ",
    "<a href=\"https://www.infogreffe.fr/formalites/declarer-des-beneficiaires-effectifs--rbe-\">Infogreffe - Déclarer les bénéficiaires effectifs (RBE)</a> ",
    "<a href=\"https://datainfogreffe.fr/register\">DataInfogreffe - Création de compte</a> ",
    "<a href=\"https://formalites.entreprises.gouv.fr/\">Guichet unique</a> ",
    "<a href=\"https://www.infogreffe.fr/formalites\">Page Formalités du site Infogreffe.fr</a> ",
    "<a href=\"https://datainfogreffe.fr/\">DataInfogreffe</a> ",
    "<a href=\"https://www.infogreffe.fr/\">Infogreffe - Entreprendre en confiance</a> ",
]

const expertsDebug = [
    {
        "id": "801c4129-4373-5564-a055-c7b466560185",
        "expertises": [
            "Droit des sociétés",
            "M&A",
            "Private Equity ",
            "Droit du travail",
            "Droit immobilier"
        ],
        "description": "\nImpulsa Avocats est une structure du groupe Impulsa, spécialiste du conseil juridique et financier composé de 100 collaborateurs et basé à Paris. Impulsa Avocats accompagne ses clients dans l'ensemble des domaines du droits des affaires (droit des sociétés, droit du travail, contentieux commerciaux, droit immobilier et contrats commerciaux, propriété intellectuelle et droit des nouvelles technologies). \n\nImpulsa Avocats détermine avec ses clients une stratégie pragmatique afin de répondre à leur besoin avec rigueur et célérité. Les modules de droit des sociétés proposés via la plateforme vous permettent d'obtenir un accompagnement complet allant de la définition de vos besoins à la réalisation des opérations.",
        "url_logo": "https://impulsaavocats.com/themes/custom/impulsa/assets/images/logo.svg",
        "type_structure": "Cabinet d'avocats",
        "solutions": [
            {
                "description": "Impulsa Avocats vous accompagne dans le cadre de la cession ou de l'acquisition de parts de SARL ou de sociétés civiles. Impulsa Avocats a notamment développé une expertise dans le cadre des cessions de parts de sociétés civiles immobilières, ou de sociétés d'exercice libérale et notamment de cession de cabinet de médecin ou d'expertise comptable. Nous établirons ensemble une stratégie pragmatique et protectrice de vos droits.",
                "type": "Prestation de service",
                "prix": "sur devis",
                "titre": "Cession de parts sociales",
                "url": "https://impulsaavocats.com/fr/node/28"
            },
            {
                "description": "Lors de l'entrée au capital de nouveaux investisseurs ou au cours de la vie sociale de l'entreprise, les associés peuvent décider d'émettre des actions de préférence.\n\nImpulsa Avocats vous accompagne afin de mieux cibler vos besoins et établir une stratégie pragmatique et protectrice de vos droits.",
                "type": "Prestation de service",
                "prix": "sur devis",
                "url": "https://impulsaavocats.com/fr/node/28",
                "titre": "Emission d'actions de préférenceEmission d'actions de préférenceEmission d'actions de préférence"
            },
            {
                "description": "Impulsa vous accompagne dans la modification de vos statuts, que cela soit lié à la modification de l'adresse du siège, d'un dirigeant, d'une clause, etc.",
                "type": "Prestation de service",
                "prix": "750€ / modifications",
                "url": "https://impulsaavocats.com/fr/node/28",
                "titre": "Modifications statutaires"
            }
        ],
        "url_site": "https://impulsaavocats.com/fr",
        "mots_cles": [
            "Droit des sociétés / M&A / Private Equity ",
            "Droit du travail",
            " Droit immobilier",
            "Contentieux commercial",
            "Droit fiscal ",
            "Création d'entreprise et société",
            "cessation d'activité",
            "dissolution-liquidation de sociétéé",
            "TUP",
            "Nomination et rémunération des dirigeants et comités",
            "approbation des comptes",
            "modifications statutaires",
            "assemblée générales",
            "pacte d'associés",
            "gestion de la sortie d'associé",
            "contrats commerciaux",
            "accords de condifentialité",
            "accords et prêts intra-groupe",
            "opérations sur capital",
            "levée de fonds",
            "emission d'obligations",
            "BSA, BSPCE, AGA et actions de préférences",
            "transferts de titres",
            "cessions/acquisitions de sociéétéés et fonds de commerce",
            "garantie de passif",
            "fusions et apport partiel d'actif",
            "procédure de conciliation",
            "mandat ad hoc"
        ],
        "name": "Impulsa Avocats"
    },
    {
        "id": "f874d1b8-29c2-5bf1-a6be-859c9d862b9c",
        "url_logo": "https://www.atoutchimie.eu/media/374/atout-chimie.png",
        "description": "Créé en 1986, Infogreffe est le Groupement d'Intérêt Economique (G.I.E.) regroupant les 141 greffes des tribunaux de commerce de l'hexagone et des départements et régions d'outre-mer. \n\nDans le prolongement de la mission de service public des greffiers, Infogreffe permet l'accès aux données légales, juridiques et économiques sur les entreprises immatriculées au registre du commerce et des sociétés, tout en s'attachant à sans cesse innover afin que chaque entrepreneur dispose d'un panel de services pour créer et pérenniser son entreprise. \n\nEngagé en faveur du numérique et pionnier de la dématérialisation, Infogreffe met à disposition des entreprises des informations sécurisées et certifiées par les greffiers, ainsi que des solutions qui facilitent les démarches des chefs d'entreprises et des professionnels du droit et du chiffre. \n\nSa principale mission : donner les moyens et outils nécessaires pour entreprendre en confiance.",
        "url_vitrine_moli": "",
        "type_structure": "Groupement d'intérêt économique Groupement d'intérêt économique (GIE)",
        "expertises": [
            "Modification",
            "Radiation",
            "Bénéficiaires effectifs",
            "Extrait KBIS",
            "documents officiels"
        ],
        "url_site": "https://www.infogreffe.fr/",
        "solutions": [
            {
                "description": "la plateforme de dématérialisation pour simplifier la gestion des Registres, des titres et des assemblées.",
                "type": "Service numérique",
                "prix": "à partir de 5€/mois",
                "url": "https://monjuridique.infogreffe.fr/",
                "titre": "MonJuridiqueInfogreffe"
            },
            {
                "description": "le portail qui vous permet de récupérer facilement vos impayés en ligne.",
                "type": "Service numérique",
                "prix": "90€ / démarche",
                "url": "https://mesimpayes.infogreffe.fr/",
                "titre": "Mes impayés infogreffe "
            },
            {
                "description": "Agir en justice, simplement et rapidement.",
                "type": "Service numérique",
                "prix": "Freemium",
                "url": "https://www.tribunaldigital.fr/",
                "titre": "Tribunal digital"
            },
            {
                "description": "l'identité numérique reconnue pour accéder aux services partenaires en ligne.",
                "type": "Service numérique",
                "prix": "Freemium",
                "url": "https://monidenum.fr/",
                "titre": "Monidenum"
            },
            {
                "description": "la marketplace infogreffe facilite la rencontre entre cédants, repreneurs et investisseurs en toute confiance.",
                "type": "Service numérique",
                "prix": "Freemium",
                "url": "https://marketplace.infogreffe.fr/",
                "titre": "Marketplace Infogreffe"
            },
            {
                "description": "Sécurigreffe permet l'amélioration des échanges entre les différents acteurs judiciaires dans le respect des obligations règlementaires. L'espace de notification sécurisé garantit l’authenticité et la fiabilité des informations et des documents transmis par voie électronique. \n\nDepuis 2016, la majorité des greffes des tribunaux de commerce utilisent cette solution de dématérialisation des notifications.",
                "type": "Service numérique",
                "prix": "Gratuit",
                "url": "https://securigreffe.infogreffe.fr/infogreffe/",
                "titre": "Securigreffe"
            }
        ],
        "mots_cles": [
            "déclaration des bénéficiaires effectifs (RBE)",
            "les statuts mis à jour",
            "les actes modifiant la composition du capital (cession de parts, donation de parts...)",
            "les documents relatifs aux bénéficiaires effectifs",
            "les projets de fusion ou d'apport partiel d'actif, ainsi que divers rapports (commissaire aux comptes, liquidateur) ",
            "La radiation d'un commerçant personne physique ou la radiation d'une société",
            "Changement de gérant ou de dénomination sociale",
            "augmentation du capital ",
            " transfert de siège",
            " modification relative au capital"
        ],
        "name": "Infogreffe"
    }
]

// Create a context with initial values
const AppContext = createContext();
const { wsUrl } = getEnvConfig()
console.log('Using WebSocket URL:', wsUrl);

var ws = new WebSocket(wsUrl);

// Create a provider component to wrap your app with the context provider
export const AppContextProvider = ({ children }) => {


    // const initialMsg = createMessage(

    //     'Bonjour, je suis Oliver votre nouvel assistant juridique (version beta), comment puis-je vous aider aujourd\'hui ?',
    //     "system",
    //     undefined,
    //     {},
    //     -1
    // )

    const getSubdomain = () => {
        const hostnameParts = window.location.hostname.split('.');
        if (hostnameParts[0] === 'www') {
            return hostnameParts.length > 2 ? hostnameParts[1] : null;
        }
        return hostnameParts[0];
    };

    const subdomain = getSubdomain();
    const subDomains = ["formalites", "mesformalites", "infogreffe"]


    // Version is 0 or 1
    const [version, setVersion] = useState(0)

    const [selectedParentIds, setSelectedParentIds] = useState([]);
    const [parentIds, setParentIds] = useState([]);
    const [isQuestionPersonnel, setIsQuestionPersonnel] = useState(false);
    const [firstShow, setFirstShow] = useState(true);


    const [customOliver, setCustomOliver] = useState(false)
    const [custom_MFL, setCustom_MFL] = useState(false)
    const [discriptionFormalite, setDiscriptionFormalite] = useState("")
    const [conversations, setConversations] = useState([]);


    const [messages, setMessages] = useState([]);
    const [compagnyInfos, setCompagnyInfos] = useState({
        "secteur_activite_principale": null,
        "forme_juridique": null,
        "effectif_moyen": null,
        "chiffre_affaires": null,
        "bilan": null
    });
    const [model, setModel] = useState("");
    const [nameModel, setNameModel] = useState("");
    const [liensUtiles, setLiensUtiles] = useState([]);
    const [experts, setExperts] = useState([]);

    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState(null)

    const [computingResponse, setComputingResponse] = useState(false);
    const [writing, setWriting] = useState(false);

    const [streamError, setStreamError] = useState(null)


    const [currentUser, setCurrentUser] = useState(null);
    const [currentConversationId, setCurrentConversationId] = useState(null);
    const [credits, setCredits] = useState(null);
    const [creditUnitPrice, setCreditUnitPrice] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isformaliste, setFormaliste] = useState(false);

    const [configCreditOliver, setConfigCreditOliver] = useState(null);

    const [cgus, setCgus] = useState(false);

    // const [location, setLocation] = useState(null);
    const [error, setError] = useState(null);

    const [nomEntreprise, setNomEntreprise] = useState("");
    const [siret, setSiret] = useState("");
    const [currentUserData, setCurrentUserData] = useState(null);

    // useEffect(() => {
    //     const fetchLocation = async () => {
    //         try {
    //             const response = await fetch('https://ipapi.co/json/');
    //             const data = await response.json();

    //             // Vérifiez si l'utilisateur est en France métropolitaine, à Mayotte ou à La Réunion
    //             // const isFranceMetropolitan = data.country === 'FR' && !['Mayotte', 'La Réunion'].includes(data.region);
    //             const isFranceMetropolitan = true;
    //             const isMayotte = data.country === 'YT' || (data.country === 'FR' && data.region === 'Mayotte');
    //             const isReunion = data.country === 'RE' || (data.country === 'FR' && data.region === 'La Réunion');

    //             console.log({
    //                 country: data.country,
    //                 region: data.region,
    //                 isFranceMetropolitan,
    //                 isMayotte,
    //                 isReunion
    //             });
    //             setLocation({
    //                 country: data.country,
    //                 region: data.region,
    //                 isFranceMetropolitan,
    //                 isMayotte,
    //                 isReunion
    //             });
    //         } catch (err) {
    //             setError('Impossible de déterminer la localisation');
    //         }
    //     };

    //     fetchLocation();
    // }, []);

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "oliver/app_settings"), (doc) => {

            const data = doc.data();
            setNameModel(data.default_model)
            switch (data.default_model) {
                case "Open Mixtral 8x7b":
                    setModel("mistral")
                    setVersion(0)
                    break;
                case "Mistral medium":
                    setModel("mistral")
                    setVersion(1)
                    break;
                case "GPT 3.5":
                    setModel("openai")
                    setVersion(0)
                    break;
                case "GPT 4 turbo":
                    setModel("openai")
                    setVersion(1)
                    break;
                default:
                    console.log(data.default_model)
            }

        });

        return () => unsubscribe();
    }, []);

    // Parents ids 
    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "oliver/parent_ids"), (doc) => {

            const data = doc.data();
            setParentIds(data);
            setSelectedParentIds(Object.keys(data));

        });

        // Unsubscribe from changes when the component is unmounted or dependencies change
        return () => unsubscribe();
    }, []);

    // Parents ids 
    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "oliver/custom_oliver"), (doc) => {
            const data = doc.data();
            setCustomOliver(data);
        });

        // Unsubscribe from changes when the component is unmounted or dependencies change
        return () => unsubscribe();
    }, []);

    // Parents ids 
    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "oliver/custom_MFL"), (doc) => {
            const data = doc.data();
            setCustom_MFL(data);
        });

        // Unsubscribe from changes when the component is unmounted or dependencies change
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, ["oliver.legal", "infogreffe.oliver.legal"].includes(window.location.hostname) ? "oliver/cgus" : "oliver/cgus_MFL"), (doc) => {
            const data = doc.data();
            setCgus(data);
        });
        // Unsubscribe from changes when the component is unmounted or dependencies change
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (currentUser) {
            const unsubscribe = onSnapshot(doc(db, `users/${currentUser.uid}`), (doc) => {
                const data = doc.data();
                if (data) {
                    setCurrentUserData(data)
                    setCredits(data.credits)
                }
            });

            // Unsubscribe from changes when the component is unmounted or dependencies change
            return () => unsubscribe();
        }
        else {
            setVersion(0)
            setCredits(null)
            setCurrentUserData(null);
        }
    }, [db, currentUser]);

    useEffect(() => {
        if (currentUser) {
            const conversationsRef = collection(db, "users", currentUser.uid, "conversations");
            // Ordonner par 'lastActive' et limiter à 20 conversations
            const q = query(conversationsRef, orderBy("lastActive", "desc"), limit(20));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const updatedConversations = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setConversations(updatedConversations); // Mise à jour de l'état avec les nouvelles conversations
            }, (error) => {
                console.error("Error fetching conversations:", error);
            });

            return () => unsubscribe(); // Cleanup on unmount
        }
    }, [db, currentUser]); // Dépendances de useEffect

    useEffect(() => {
        if (!credits) {
            setVersion(0)
        }
    }, [credits])


    // useEffect(() => {
    //     var parentIdskeys = Object.keys(parentIds)
    //     const option_list = [
    //         { "name": "Checkup d'une entreprise", "class": "filter_button_checkup_entreprise", "action": { "name": "checkup_entreprise" } },
    //         { "name": "formalités d'entreprise", "class": "filter_button_suivre_formalite", "action": { "name": "formalités_d_entreprise" } },
    //     ]
    //     const optionsInfogreffe_list = [
    //         { "name": "Les décisions de justice", "class": "filter_button_decision_justice", "action": { "name": "filter", "parentIds": [] } },
    //         { "name": "Les démarches administratives", "class": "filter_button_demarche_administrative", "action": { "name": "filter", "parentIds": ["vos_droits"] } },
    //         { "name": "La réglementation française", "class": "filter_button_reglementation_francaise", "action": { "name": "filter", "parentIds": parentIdskeys.filter(key => key.startsWith("legitext")) } },
    //         { "name": "Les services Infogreffe", "class": "filter_button_service_infogreffe", "action": { "name": "filter", "parentIds": ["fiche_renforcement_infogreffe"] } },
    //     ]

    //     if (subdomain == "infogreffe") {
    //         setOptions(option_list)
    //         // setOptions(optionsInfogreffe_list)
    //     }
    // }, [parentIds]);


    const option_filter = () => {
        setWriting(true);
        setSelectedParentIds(selectedOption["action"]["parentIds"]);


        var newMsg = createMessage("", "system", undefined, {}, -1);
        updateMessage(newMsg, setMessages);

        const sentence = "Merci, je vais donc vous répondre en allant chercher en priorité dans les sources qui me paraissent les plus adaptées.\n Comment puis-je vous aider ? ".split(" ");

        const printWordByWord = async () => {
            for (const word of sentence) {
                // Generate a random timeout between 1 and 5 seconds (in milliseconds)
                const randomTimeout = Math.floor(Math.random() * 10) + 20;
                await new Promise(resolve => setTimeout(resolve, randomTimeout));
                update_last_system_message_tokens(`${word} `, setMessages);
            }
        };
        printWordByWord();
        setWriting(false)
    }

    const option_checkup_entreprise = () => {
        setWriting(true);
        var newMsg = createMessage("", "system", undefined, {}, -2);
        updateMessage(newMsg, setMessages);

        const sentence = "Pour faire le checkup d'une entreprise, veuillez renseigner sa dénomination ou son numéro de Siren :".split(" ");

        const printWordByWord = async () => {
            for (const word of sentence) {
                // Generate a random timeout between 1 and 5 seconds (in milliseconds)
                const randomTimeout = Math.floor(Math.random() * 10) + 60;
                await new Promise(resolve => setTimeout(resolve, randomTimeout));
                update_last_system_message_tokens(`${word} `, setMessages);
            }
        };
        printWordByWord();
        setWriting(false)
    }

    const option_suivre_formalite = () => {
        setWriting(true);
        window.open('https://www.infogreffe.fr/formalites/rechercher-une-formalite', '_blank');
        setWriting(false)
    }

    const option_formalités_d_entreprise = () => {
        setWriting(true);
        const newMsg = createMessage("", "system", undefined, {}, -2);
        updateMessage(newMsg, setMessages);

        const sentence = "Que souhaitez-vous faire ?".split(" ");

        const printWordByWord = async () => {
            for (const word of sentence) {
                // Generate a random timeout between 1 and 5 seconds (in milliseconds)
                const randomTimeout = Math.floor(Math.random() * 10) + 60;
                await new Promise(resolve => setTimeout(resolve, randomTimeout));
                update_last_system_message_tokens(`${word} `, setMessages);
            }
        };
        printWordByWord();
        const option_list = [
            { "name": "effectuer une formalités", "class": "filter_button_suivre_formalite", "action": { "name": "formalités_d_entreprise" } },
            { "name": "suivre une formalite", "class": "filter_button_suivre_formalite", "action": { "name": "formalités_d_entreprise" } },
        ]
        setOptions(option_list)
        setWriting(false)
    }

    // Filters
    // useEffect(() => {
    //     if (selectedOption) {
    //         var newMsg = createMessage("", "user", undefined, {}, -1);
    //         updateMessage(newMsg, setMessages);
    //         const action = selectedOption["action"]
    //         if (action["name"] == "filter") option_filter()
    //         else if (action["name"] == "checkup_entreprise") option_checkup_entreprise()
    //         else if (action["name"] == "suivre_formalite") option_suivre_formalite()
    //         else if (action["name"] == "formalités_d_entreprise") {
    //             option_formalités_d_entreprise()
    //         }
    //     }
    // }, [selectedOption]);
    const [isFormality, setIsFormality] = useState(false);
    const [currentSection, setCurrentSection] = useState("Chatbot")

    const clearChat = () => {
        setMessages([])
        setLiensUtiles([])
        setExperts([])
        setSelectedOption(null)
        setStreamError(false)
        setCurrentSection("Chatbot");
        setWriting(false)
        setComputingResponse(false)
        setIsFormality(false);
        setFirstShow(true);
        ws.close()
    }

    const formalitieJuridique = (nomEntreprise = "", siret = "") => {
        setIsFormality(true);
        setNomEntreprise(nomEntreprise);
        setSiret(siret);
        setCurrentSection("Formalité juridique")
        const option = {
            'class': 'filter_button_suivre_formalite',
            'name': "Réaliser une formalité juridique",
            'action': 'formalities',
            'key': 0
        }
        const newMsg = createMessage(option.name, "user", undefined, {}, -1);
        updateMessage(newMsg, setMessages);
        setSelectedOption({ ...option, displayOptionsFormalite: false });

        setWriting(true);
        const newMsg1 = createMessage("", "system", undefined, {}, -2);
        updateMessage(newMsg1, setMessages);

        const sentence = "Que souhaitez-vous faire ?".split(" ");

        const printWordByWord = async () => {
            for (const word of sentence) {
                const randomTimeout = Math.floor(Math.random() * 10) + 60;
                await new Promise(resolve => setTimeout(resolve, randomTimeout));
                update_last_system_message_tokens(`${word} `, setMessages);
            }
            setWriting(false);
        };
        printWordByWord();

    }

    const handleCloseWS = () => {
        setComputingResponse(false)
        setWriting(false)
        ws.close();
        console.log('WebSocket connection closed by user.');
    }

    const auth = getAuth();

    // const location = useLocation();

    // const queryParams = new URLSearchParams(location.search);
    // const mode = queryParams.get('mode');
    // const oobCode = queryParams.get('oobCode');
    // useEffect(() => {
    // if (mode && oobCode) {
    //     console.log("mode: " + mode)
    //     console.log("oobCode: " + oobCode)
    //     applyActionCode(auth, oobCode).then(() => {
    //         // Email address has been verified.
    //         alert("Votre email a été vérifié avec succès !")
    //         const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
    //         window.history.pushState({ path: newUrl }, '', newUrl);

    //     }).catch((error) => {
    //         alert("Erreur lors de la vérification de votre email")
    //         const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
    //         window.history.pushState({ path: newUrl }, '', newUrl);
    //     });
    // }
    // }, [mode, oobCode])



    const getAccessToken = () => {
        const user = auth.currentUser;
        if (user) {
            const token = user.getIdTokenResult().then((token) => {
                return token.token
            })
            return token;
        }
        else return null;
    }


    const retryMessage = async () => {
        // Check if the WebSocket connection is open
        if (ws.readyState === WebSocket.OPEN) {
            setStreamError(false)
            try {
                const conversation = [...messages].filter(msg => msg.id != -1)
                ws.send(JSON.stringify({
                    // conversation: [...messages, newMsg].slice(1),
                    //Ignore historique
                    conversation: conversation,
                    selectedCodes: selectedParentIds,
                    compagnyInfos: compagnyInfos,
                    version: version,
                    model: model,
                    name_model: nameModel,
                    token: await getAccessToken()
                }));
            } catch (err) {
                window.alert(`Error: ${err} please try again later`);
            }
        } else if (ws.readyState === WebSocket.CONNECTING) {
            // If the WebSocket is still connecting, wait and try again after a brief delay
            setTimeout(() => {
                retryMessage();
            }, 500);
        } else {
            console.log("WebSocket closed");
            // If the WebSocket connection is closed, reconnect and then send the message
            ws.close();
            ws = new WebSocket(wsUrl);
            retryMessage();
        }
    }


    const sendMessage = async (formValue, setFormValue) => {
        // Check if the WebSocket connection is open
        console.log("formValue: ", formValue)
        // setSelectedOption(null);
        console.log("ws.readyState: ", ws.readyState)
        setWriting(true);
        // updateMessage("je compile", setMessages)


        if (ws.readyState === WebSocket.OPEN) {
            var newMsg = createMessage(formValue, "user");
            console.log("newMsg: ", newMsg);
            setComputingResponse(true);
            setFormValue("");
            updateMessage(newMsg, setMessages);
            console.log("newMsg1: ", newMsg);

            try {
                const conversation = [...messages, newMsg].filter(msg => msg.id != -1)
                const data = JSON.stringify({
                    // conversation: [...messages, newMsg].slice(1),
                    //Ignore historique
                    conversation: conversation,
                    selectedCodes: selectedParentIds,
                    compagnyInfos: compagnyInfos,
                    version: version,
                    model: model,
                    name_model: nameModel,
                    token: await getAccessToken()
                })
                // console.log(data)
                ws.send(data);
            } catch (err) {
                window.alert(`Error: ${err} please try again later`);
            }

            if (currentUser && currentConversationId) {
                try {
                    const conversationRef = doc(db, "users", currentUser.uid, "conversations", currentConversationId);
                    await updateDoc(conversationRef, {
                        messages: arrayUnion(newMsg),
                        lastActive: serverTimestamp()
                    });

                } catch (err) {
                    console.error(`Error in sendMessage: ${err}`);
                }
            }

            var newMsg = createMessage("", "system");
            updateMessage(newMsg, setMessages);
        } else if (ws.readyState === WebSocket.CONNECTING) {
            // If the WebSocket is still connecting, wait and try again after a brief delay
            setTimeout(() => {
                sendMessage(formValue, setFormValue);
            }, 500);
        } else {
            console.log("WebSocket closed");
            // If the WebSocket connection is closed, reconnect and then send the message
            ws.close();
            ws = new WebSocket(wsUrl);
            sendMessage(formValue, setFormValue);
        }
    };


    ws.onmessage = async function (event) {
        const jsonSocket = JSON.parse(event.data);
        // console.log("onmessage: ", jsonSocket)
        if (jsonSocket["type"] === "documents") {
            update_last_system_message_documents(jsonSocket["content"], setMessages)
        }
        else if (jsonSocket["type"] === "liens_utiles") {
            setLiensUtiles(jsonSocket["content"])
        }
        else if (jsonSocket["type"] === "experts") {
            const filteredExperts = jsonSocket["content"].filter(expert => !expert?.black_list?.includes(subdomain));
            setExperts(filteredExperts);
        }
        else if (jsonSocket["type"] === "token") {
            if (jsonSocket["content"] == null) {
                setWriting(false)
                // TODO ajouter la repense du chatbot ...
                console.log("messages[-1]: ", messages[messages.length - 1]);
                console.log("jsonSocket['title'] ", jsonSocket["title"]);
                if (currentUser && currentConversationId) {
                    try {
                        const conversationRef = doc(db, "users", currentUser.uid, "conversations", currentConversationId);
                        await updateDoc(conversationRef, {
                            titre: jsonSocket["title"],
                            messages: arrayUnion(messages[messages.length - 1]),
                            lastActive: serverTimestamp()
                        });
                    } catch (err) {
                        console.error(`Error in sendMessage: ${err}`);
                    }
                }
            }
            else {
                if (!writing) setWriting(true)
                update_last_system_message_tokens(jsonSocket["content"], setMessages)
            }
        }
        else if (jsonSocket["type"] === "status") {
            if (jsonSocket["content"] == "streaming_error") {
                setWriting(false)
                setStreamError(true)
            }
            else if (jsonSocket["content"] == "end_response") {
                setComputingResponse(false)
            }
        }
        else {
            console.log("Error: unknown message type " + jsonSocket["type"])
        }

    };

    // useEffect(() => {
    //     async function fetchConfig() {
    //         const response = await fetchData("config-credit-oliver", null, "GET");
    //         setConfigCreditOliver(response)
    //     }
    //     if (currentUser) {
    //         // console.log("currentUser :", currentUser);
    //         fetchConfig();
    //     }
    // }, [currentUser])

    useEffect(() => {
        if (configCreditOliver) {
            setCreditUnitPrice(configCreditOliver.unitAmount / 100);
        }
    }, [configCreditOliver]);



    return (
        <AppContext.Provider value={{
            selectedParentIds,
            messages,
            compagnyInfos,
            liensUtiles,
            model,
            experts,
            parentIds,
            options,
            selectedOption,
            version,
            streamError,
            writing,
            computingResponse,
            currentUser,
            isAdmin,
            isformaliste,
            credits,
            creditUnitPrice,
            configCreditOliver,
            customOliver,
            custom_MFL,
            cgus,
            subdomain,
            conversations,
            subDomains,
            nameModel,
            currentSection,
            isFormality,
            discriptionFormalite,
            // location,
            currentConversationId,
            isQuestionPersonnel,
            firstShow,
            siret,
            nomEntreprise,
            currentUserData,

            setIsQuestionPersonnel,
            setFirstShow,
            setCurrentConversationId,
            setSelectedParentIds,
            setDiscriptionFormalite,
            setMessages,
            setCompagnyInfos,
            setLiensUtiles,
            setModel,
            setExperts,
            setSelectedOption,
            setVersion,
            setStreamError,
            setCurrentUser,
            setIsAdmin,
            setFormaliste,
            setWriting,
            setNameModel,
            setCurrentSection,
            clearChat,
            sendMessage,
            retryMessage,
            handleCloseWS,
            setIsFormality,
            formalitieJuridique,
        }}>
            {children}
        </AppContext.Provider>
    );
};

// Custom hook to access the context values
export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useMyContext must be used within a MyContextProvider');
    }
    return context;
};
