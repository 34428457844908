import { getAuth } from "firebase/auth";
import { getEnvConfig } from "./config";

const auth = getAuth();

export const getUser = () => {
  const user = auth.currentUser;
  if (user) {
    return user;
  }
  else return null;
}

export const getAccessToken = () => {
  const user = auth.currentUser;
  if (user) {
    const token = user.getIdTokenResult().then((token) => {
      return token.token
    })
    return token;
  }
  else return null;
}


export const fetchData = async (route, payload, method, isFile = false) => {
  const token = await getAccessToken();
  const { apiUrl } = getEnvConfig();
  const fullUrl = apiUrl + route;
  console.log("apiUrl: ", apiUrl);
  console.log("fullUrl: ", fullUrl);

  try {
    let body = null;
    let headers = {
      "Authorization": `Bearer ${token}`,
    };

    if (method === "POST" || method === "PUT") {
      if (isFile) {
        const formData = new FormData();
        Object.keys(payload).forEach(key => {
          formData.append(key, payload[key]);
        });
        body = formData;
      } else {
        body = JSON.stringify(payload);
        headers["Content-Type"] = "application/json";
      }
    }

    const response = await fetch(fullUrl, {
      method: method,
      headers: headers,
      body: body,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    if (response.headers.get("content-type")?.includes("application/json")) {
      const data = await response.json();
      return data;
    } else {
      return await response.text();
    }
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw error;
  }
};
