import React, { useState, useEffect } from 'react';
import { Search, UserCog } from 'lucide-react';
import { useAppContext } from '../../../context/AppContext';
import { fetchData } from '../../../utils/ask_api';
import { Dropdown } from 'flowbite-react';

const UserManagementModal = ({ user, isOpen, onClose, categories, onSave }) => {

    console.log('UserManagementModal user : ', user)
    const [role, setRole] = useState(user?.role || 'user');
    const [selectedCat, setSelectedCat] = useState(user?.categoriesFormalites?.[0] || '');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        try {
            // Validation côté client
            if (role === 'formaliste' && !selectedCat) {
                setError('Veuillez sélectionner au moins une catégorie pour un formaliste');
                return;
            }

            await onSave(user.email, role, selectedCat ? [selectedCat] : []);
            onClose();

        } catch (error) {
            setError(error.message);
        }
    };


    useEffect(() => {
        setRole(user?.role || 'user')
        setSelectedCat(user?.categoriesFormalites?.[0] || '');
    }, [user]);

    console.log("role : ", role)

    return (
        isOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg p-6 w-full max-w-md">
                    <h3 className="text-lg font-medium mb-4">
                        Gestion des droits - {user.email}
                    </h3>
                    {error && (
                        <div className="mb-4 text-red-600 bg-red-50 p-2 rounded">
                            {error}
                        </div>
                    )}
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Rôle
                            </label>
                            <select
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            >
                                <option value="user">Utilisateur</option>
                                <option value="formaliste">Formaliste</option>
                                <option value="admin">Administrateur</option>
                            </select>
                        </div>

                        {role === 'formaliste' && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Catégorie de formalité
                                </label>
                                <div className="space-y-2">
                                    {categories.map(category => (
                                        <div key={category.id} className="flex items-center p-2 rounded hover:bg-gray-50">
                                            <input
                                                type="radio"
                                                id={category.id}
                                                name="category"
                                                value={category.id}
                                                checked={selectedCat === category.id}
                                                onChange={(e) => setSelectedCat(e.target.value)}
                                                className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                            />
                                            <label htmlFor={category.id} className="ml-2 flex flex-col cursor-pointer">
                                                <span className="text-sm font-medium text-gray-900">{category.nom}</span>
                                                <span className="text-sm text-gray-500">SIRET: {category.siret}</span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div className="flex justify-end space-x-2 pt-4">
                            <button
                                type="button"
                                onClick={onClose}
                                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                            >
                                Annuler
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                            >
                                Enregistrer
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    );
};

const UsersManagement = () => {
    const { isAdmin } = useAppContext();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterRole, setFilterRole] = useState('all');
    const [filterCategory, setFilterCategory] = useState('all');
    const [categories, setCategories] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);

    const fetchUsers = async () => {
        try {
            const usersData = await fetchData('users', null, 'GET');
            setUsers(usersData);
            setError(null);
        } catch (error) {
            console.error("Erreur lors de la récupération des utilisateurs:", error);
            setError("Impossible de charger les utilisateurs");
        } finally {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const categoriesData = await fetchData('categories-formalites', null, 'GET');
            console.log("categoriesData :", categoriesData)
            setCategories(categoriesData);
        } catch (error) {
            console.error("Erreur lors de la récupération des catégories:", error);
        }
    };

    const updateUserRole = async (email, role, selectedCategories = []) => {
        try {
            const response = await fetchData('users/role', {
                email,
                role,
                categories: selectedCategories
            }, 'PUT');

            await fetchUsers(); // Rafraîchir la liste
            setError(null);
            return response;

        } catch (error) {
            console.error("Erreur lors de la mise à jour du rôle:", error);
            let errorMessage = "Erreur lors de la mise à jour du rôle";

            if (error.response) {
                errorMessage = error.response.detail || errorMessage;
            }

            throw new Error(errorMessage);
        }
    };

    const toggleUserStatus = async (userId) => {
        try {
            await fetchData(`users/${userId}/toggle-status`, null, 'PUT');
            await fetchUsers(); // Rafraîchir la liste
        } catch (error) {
            console.error("Erreur lors de la modification du statut:", error);
            setError("Impossible de modifier le statut");
        }
    };

    useEffect(() => {
        if (isAdmin) {
            fetchUsers();
            fetchCategories();
        } else {
            setError("Accès non autorisé");
            setLoading(false);
        }
    }, [isAdmin]);

    console.log("users : ", users)
    const filteredUsers = users?.filter(user => {
        const matchesSearch = user.email.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesRole = filterRole === 'all' || user.role === filterRole;
        const matchesCategory = filterCategory === 'all' ||
            (user.categoriesFormalites && user.categoriesFormalites.includes(filterCategory));

        return matchesSearch && matchesRole && matchesCategory;
    });

    if (error) {
        return (
            <div className="p-4 text-red-600 bg-red-50 rounded-md">
                {error}
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-6">
            {/* Filtres */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="relative">
                    <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-500" />
                    <input
                        type="text"
                        placeholder="Rechercher par email..."
                        className="pl-10 p-2 w-full border rounded-md"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                <select
                    className="p-2 border rounded-md"
                    value={filterRole}
                    onChange={(e) => setFilterRole(e.target.value)}
                >
                    <option value="all">Tous les rôles</option>
                    <option value="user">Utilisateurs</option>
                    <option value="formaliste">Formalistes</option>
                    <option value="admin">Administrateurs</option>
                </select>

                <select
                    className="p-2 border rounded-md"
                    value={filterCategory}
                    onChange={(e) => setFilterCategory(e.target.value)}
                >
                    <option value="all">Toutes les catégories de formalistes</option>
                    {categories.length > 0 && categories?.map(category => (
                        <option key={category.id} value={category.id}>
                            {category.nom}
                        </option>
                    ))}
                </select>
            </div>

            {/* Table des utilisateurs */}
            <div className="overflow-x-auto rounded-lg border border-gray-200">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Email
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Rôle
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Catégories
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Statut
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {loading ? (
                            <tr>
                                <td colSpan="5" className="px-6 py-4 text-center">
                                    <div className="flex justify-center items-center space-x-2">
                                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
                                        <span>Chargement...</span>
                                    </div>
                                </td>
                            </tr>
                        ) : filteredUsers.length === 0 ? (
                            <tr>
                                <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                                    Aucun utilisateur trouvé
                                </td>
                            </tr>
                        ) : (
                            filteredUsers.map((user) => (
                                <tr key={user.uid} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 py-1 rounded-full text-xs ${user.role === 'admin' ? 'bg-red-100 text-red-800' :
                                            user.role === 'formaliste' ? 'bg-green-100 text-green-800' :
                                                'bg-gray-100 text-gray-800'
                                            }`}>
                                            {user.role}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className="flex flex-wrap gap-1">
                                            {user.categoriesFormalites?.map(catId => {
                                                const category = categories.find(c => c.id === catId);
                                                return category ? (
                                                    <>
                                                        <span key={catId} className="px-2 py-1 text-xs rounded-full">
                                                            {category.nom}
                                                        </span>
                                                        <span key={catId} className="px-2 py-1 italic bg-blue-100 text-blue-800 text-xs rounded-full">
                                                            {category.siret}
                                                        </span>
                                                    </>
                                                ) : null;
                                            })}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 py-1 rounded-full text-xs ${user.disabled ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
                                            }`}>
                                            {user.disabled ? 'Désactivé' : 'Actif'}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4">
                                        <Dropdown
                                            label={<UserCog className="h-5 w-5 text-blue-600 hover:text-blue-300" />}
                                            arrowIcon={false}
                                            inline
                                        >
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setSelectedUser(user);
                                                    setIsModalOpen(true);
                                                }}
                                            >
                                                Gérer les droits
                                            </Dropdown.Item>
                                            {/* <Dropdown.Item
                                                onClick={() => toggleUserStatus(user.uid)}
                                            >
                                                {user.disabled ? 'Activer' : 'Désactiver'} le compte
                                            </Dropdown.Item> */}
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            <UserManagementModal
                user={selectedUser}
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setSelectedUser(null);
                }}
                categories={categories}
                onSave={updateUserRole}
            />
        </div>
    );
};

export default UsersManagement;