import React, { useState, useEffect } from 'react';
import { PieChart, ChevronRight, AlertCircle, Save, CheckCircle } from 'lucide-react';
import { fetchData } from '../../../utils/ask_api';

const FormalistesConfiguration = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [saveSuccess, setSaveSuccess] = useState(false);

    // Chargement initial des catégories et leurs pourcentages
    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const data = await fetchData('categories-formalites', null, 'GET');
            // Ajout du pourcentage par défaut si non défini
            const categoriesWithPercentage = data.map(cat => ({
                ...cat,
                percentage: cat.percentage || 0
            }));
            setCategories(categoriesWithPercentage);
            setError(null);
        } catch (error) {
            setError("Impossible de charger les catégories");
            console.error("Erreur:", error);
        } finally {
            setLoading(false);
        }
    };

    const updatePercentage = (id, newValue) => {
        const updatedCategories = categories.map(cat => {
            if (cat.id === id) {
                return { ...cat, percentage: Number(newValue) };
            }
            return cat;
        });
        setCategories(updatedCategories);
    };

    const getTotalPercentage = () => {
        return categories.reduce((sum, cat) => sum + (cat.percentage || 0), 0);
    };

    const handleSave = async () => {
        const total = getTotalPercentage();
        if (total !== 100) {
            setError("Le total des pourcentages doit être égal à 100%");
            return;
        }

        setSaving(true);
        try {
            await fetchData('categories-formalites/percentages', {
                percentages: categories.map(cat => ({
                    id: cat.id,
                    percentage: cat.percentage
                }))
            }, 'PUT');
            setError(null);
            setSaveSuccess(true);
            // Faire disparaître le message de succès après 3 secondes
            setTimeout(() => {
                setSaveSuccess(false);
            }, 3000);
        } catch (error) {
            setError("Erreur lors de la sauvegarde des pourcentages");
            console.error("Erreur:", error);
        } finally {
            setSaving(false);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between">
                <h2 className="text-lg font-semibold flex items-center gap-2">
                    <PieChart className="h-5 w-5" />
                    Configuration des répartitions
                </h2>
                <button
                    onClick={handleSave}
                    disabled={saving || getTotalPercentage() !== 100}
                    className={`flex items-center gap-2 px-4 py-2 rounded-md ${saving || getTotalPercentage() !== 100
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-blue-600 hover:bg-blue-700 text-white'
                        }`}
                >
                    <Save className="h-4 w-4" />
                    {saving ? 'Sauvegarde...' : 'Sauvegarder'}
                </button>
            </div>

            {error && (
                <div className="bg-red-50 border-l-4 border-red-400 p-4 flex items-center gap-2">
                    <AlertCircle className="text-red-400" />
                    <p className="text-red-700">{error}</p>
                </div>
            )}

            {saveSuccess && (
                <div className="bg-green-50 border-l-4 border-green-400 p-2 flex items-center gap-2 transition-opacity duration-500">
                    <CheckCircle className="text-green-400" />
                    <p className="text-green-700">Les pourcentages ont été sauvegardés avec succès</p>
                </div>
            )}

            <div className="bg-white rounded-lg shadow overflow-hidden">
                <div className="p-6 space-y-6">
                    <p className="text-gray-600">
                        Configuration de la répartition des formalités.
                        Le total doit être égal à 100%.
                    </p>

                    <div className="space-y-4">
                        {categories.map((category) => (
                            <div key={category.id} className="flex items-center space-x-4">
                                <div className="flex-1 flex items-center gap-2">
                                    <ChevronRight className="h-4 w-4 text-gray-400" />
                                    <div>
                                        <p className="font-medium">{category.nom}</p>
                                        <p className="text-sm text-gray-500">SIRET: {category.siret}</p>
                                    </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <input
                                        type="number"
                                        min="0"
                                        max="100"
                                        value={category.percentage}
                                        onChange={(e) => updatePercentage(category.id, e.target.value)}
                                        className="w-20 border rounded-md p-2 text-right"
                                    />
                                    <span>%</span>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex justify-end items-center gap-2 pt-4 border-t">
                        <span className="font-medium">Total:</span>
                        <span className={`${getTotalPercentage() === 100 ? 'text-green-600' : 'text-red-600'
                            } font-bold`}>
                            {getTotalPercentage()}%
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormalistesConfiguration;
