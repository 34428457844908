import React, { useState, useEffect } from 'react';
import { Plus, Edit2, Trash2, AlertCircle } from 'lucide-react';
import { fetchData } from '../../../utils/ask_api';

const CategoriesManagement = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [categoryCounts, setCategoryCounts] = useState({});
    const [formData, setFormData] = useState({
        nom: '',
        siret: '',
        description: ''
    });

    const [validationErrors, setValidationErrors] = useState({
        nom: '',
        siret: '',
        description: ''
    });

    const fetchCategoryCounts = async () => {
        try {
            const data = await fetchData('categories-formalites/counts', null, 'GET');
            setCategoryCounts(data);
        } catch (error) {
            console.error("Erreur lors de la récupération des comptages:", error);
        }
    };

    // Fetch categories
    const fetchCategories = async () => {
        try {
            const data = await fetchData('categories-formalites', null, 'GET');
            setCategories(data);
        } catch (error) {
            setError("Impossible de charger les catégories");
            console.error("Erreur:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchCategories();
            await fetchCategoryCounts();
        };
        fetchData();
    }, []);

    const validateForm = () => {
        const errors = {};
        let isValid = true;

        // Validation du nom
        if (!formData.nom.trim()) {
            errors.nom = "Le nom est requis";
            isValid = false;
        }

        // Validation du SIRET (14 caractères numériques)
        const siretRegex = /^[0-9]{14}$/;
        if (!siretRegex.test(formData.siret)) {
            errors.siret = "Le SIRET doit contenir exactement 14 chiffres";
            isValid = false;
        }

        // Vérifier si le SIRET existe déjà
        const siretExists = categories.some(cat =>
            cat.siret === formData.siret && cat.id !== (editingCategory?.id || '')
        );
        if (siretExists) {
            errors.siret = "Ce numéro SIRET est déjà utilisé";
            isValid = false;
        }

        // // Validation de la description
        // if (!formData.description.trim()) {
        //     errors.description = "La description est requise";
        //     isValid = false;
        // }

        setValidationErrors(errors);
        return isValid;
    };

    // Create or update category
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        try {
            const payload = {
                ...formData,
                siret: formData.siret.trim(),
                nom: formData.nom.trim(),
                description: formData.description.trim()
            };

            if (editingCategory) {
                await fetchData(`categories-formalites/${editingCategory.id}`, payload, 'PUT');
            } else {
                await fetchData('categories-formalites', payload, 'POST');
            }
            fetchCategories();
            handleCloseModal();
        } catch (error) {
            if (error.message.includes('SIRET')) {
                setValidationErrors(prev => ({
                    ...prev,
                    siret: "Ce numéro SIRET est déjà utilisé"
                }));
            } else {
                setError("Erreur lors de l'enregistrement de la catégorie");
            }
            console.error("Erreur:", error);
        }
    };

    // Delete (soft) category
    const handleDelete = async (categoryId) => {
        if (window.confirm('Êtes-vous sûr de vouloir désactiver cette catégorie ?')) {
            try {
                await fetchData(`categories-formalites/${categoryId}`, null, 'DELETE');
                fetchCategories();
            } catch (error) {
                setError("Impossible de désactiver la catégorie");
                console.error("Erreur:", error);
            }
        }
    };

    const handleOpenModal = (category = null) => {
        setEditingCategory(category);
        setFormData(category || { nom: '', description: '', siret: '' });
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setEditingCategory(null);
        setFormData({ nom: '', description: '' });
    };

    return (
        <div className="space-y-6">
            {/* En-tête */}
            <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">Catégories de formalités</h2>
                <button
                    onClick={() => handleOpenModal()}
                    className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md flex items-center gap-2"
                >
                    <Plus size={20} />
                    Nouvelle catégorie
                </button>
            </div>

            {/* Message d'erreur */}
            {error && (
                <div className="bg-red-50 border-l-4 border-red-400 p-4 flex items-center gap-2">
                    <AlertCircle className="text-red-400" />
                    <p className="text-red-700">{error}</p>
                </div>
            )}

            {/* Liste des catégories */}
            <div className="bg-white rounded-lg shadow overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Nom
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Siret
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Description
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Statut
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Nb de formalités
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {loading ? (
                            <tr>
                                <td colSpan="4" className="px-6 py-4 text-center">
                                    <div className="flex justify-center items-center space-x-2">
                                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
                                        <span>Chargement...</span>
                                    </div>
                                </td>
                            </tr>
                        ) : categories.length === 0 ? (
                            <tr>
                                <td colSpan="4" className="px-6 py-4 text-center text-gray-500">
                                    Aucune catégorie trouvée
                                </td>
                            </tr>
                        ) : (
                            categories.map((category) => (
                                <tr key={category.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">{category.nom}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{category.siret}</td>
                                    <td className="px-6 py-4">{category.description}</td>
                                    <td className="px-6 py-4">
                                        <span className={`px-2 py-1 rounded-full text-xs ${category.active
                                            ? 'bg-green-100 text-green-800'
                                            : 'bg-red-100 text-red-800'
                                            }`}>
                                            {category.active ? 'Actif' : 'Inactif'}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="px-2 py-1 rounded-full text-xs bg-blue-100 text-blue-800">
                                            {categoryCounts[category.id] || 0}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className="flex space-x-2">
                                            <button
                                                onClick={() => handleOpenModal(category)}
                                                className="text-blue-600 hover:text-blue-800"
                                            >
                                                <Edit2 size={18} />
                                            </button>
                                            {/* <button
                                                onClick={() => handleDelete(category.id)}
                                                className="text-red-600 hover:text-red-800"
                                            >
                                                <Trash2 size={18} />
                                            </button> */}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            {/* Modal de création/édition */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-20 flex -top-6 items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 w-full max-w-md">
                        <h3 className="text-lg font-medium mb-4">
                            {editingCategory ? 'Modifier la catégorie' : 'Nouvelle catégorie'}
                        </h3>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Nom
                                </label>
                                <input
                                    type="text"
                                    value={formData.nom}
                                    onChange={(e) => {
                                        setFormData({ ...formData, nom: e.target.value });
                                        setValidationErrors(prev => ({ ...prev, nom: '' }));
                                    }}
                                    className={`mt-1 block w-full border rounded-md shadow-sm p-2 
            ${validationErrors.nom ? 'border-red-500' : 'border-gray-300'}`}
                                    required
                                />
                                {validationErrors.nom && (
                                    <p className="mt-1 text-sm text-red-600">{validationErrors.nom}</p>
                                )}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    SIRET
                                </label>
                                <input
                                    type="text"
                                    value={formData.siret}
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 14);
                                        setFormData({ ...formData, siret: value });
                                        setValidationErrors(prev => ({ ...prev, siret: '' }));
                                    }}
                                    className={`mt-1 block w-full border rounded-md shadow-sm p-2 
            ${validationErrors.siret ? 'border-red-500' : 'border-gray-300'}`}
                                    // placeholder="14 chiffres"
                                    maxLength={14}
                                    required
                                />
                                {validationErrors.siret && (
                                    <p className="mt-1 text-sm text-red-600">{validationErrors.siret}</p>
                                )}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Description
                                </label>
                                <textarea
                                    value={formData.description}
                                    onChange={(e) => {
                                        setFormData({ ...formData, description: e.target.value });
                                        setValidationErrors(prev => ({ ...prev, description: '' }));
                                    }}
                                    className={`mt-1 block w-full border rounded-md shadow-sm p-2 
            ${validationErrors.description ? 'border-red-500' : 'border-gray-300'}`}
                                    rows="3"
                                    required
                                />
                                {validationErrors.description && (
                                    <p className="mt-1 text-sm text-red-600">{validationErrors.description}</p>
                                )}
                            </div>
                            <div className="flex justify-end space-x-2">
                                <button
                                    type="button"
                                    onClick={handleCloseModal}
                                    className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                                >
                                    Annuler
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                                >
                                    {editingCategory ? 'Modifier' : 'Créer'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CategoriesManagement;