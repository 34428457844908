import React, { useState } from 'react';
import UsersManagement from './formalites/UsersManagement';
import CategoriesManagement from './formalites/CategoriesManagement';
import FormalistesConfiguration from './formalites/FormalistesConfiguration';
// import FormalistesConfiguration from './formalites/FormalistesConfiguration';

const GestionFormalites = () => {
    const [activeTab, setActiveTab] = useState('users');

    return (
        <div className="flex flex-col w-full">
            {/* Navigation entre les sections */}
            <div className="flex space-x-4 mb-6 border-b border-gray-200">
                <button
                    className={`px-4 py-2 font-semibold ${activeTab === 'users'
                        ? 'text-blue-600 border-b-2 border-blue-600'
                        : 'text-gray-600 hover:text-blue-600'
                        }`}
                    onClick={() => setActiveTab('users')}
                >
                    Utilisateurs
                </button>
                <button
                    className={`px-4 py-2 font-semibold ${activeTab === 'categories'
                        ? 'text-blue-600 border-b-2 border-blue-600'
                        : 'text-gray-600 hover:text-blue-600'
                        }`}
                    onClick={() => setActiveTab('categories')}
                >
                    Formalistes
                </button>
                <button
                    className={`px-4 py-2 font-semibold ${activeTab === 'formalistes'
                        ? 'text-blue-600 border-b-2 border-blue-600'
                        : 'text-gray-600 hover:text-blue-600'
                        }`}
                    onClick={() => setActiveTab('formalistes')}
                >
                    Configuration Formalistes
                </button>
            </div>

            {/* Contenu des sections */}
            <div className="flex-1">
                {activeTab === 'users' && <UsersManagement />}
                {activeTab === 'categories' && <CategoriesManagement />}
                {activeTab === 'formalistes' && <FormalistesConfiguration />}
            </div>
        </div>
    );
};

export default GestionFormalites;